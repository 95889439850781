import React from 'react';

import SuccessOverlay from './SuccessOverlay';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Modal from '@dealroadshow/uikit/core/components/Modal';

import finsightLogo from '@/Framework/assets/logo/finsightLogo.inline.svg';
import backgroundLogo from '@/Framework/assets/logo/logoWings.inline.svg';

import styles from './finsightModal.scss';

interface IFinsightModalProps {
  children: React.ReactNode,
  modalTitle: string | React.ReactNode,
  dataTest: string,
  isFetching: boolean,
  isVisible: boolean,
  isSuccessOverlay?: boolean,
  successText?: string,
  isSubmitSucceed?: boolean,
  hideOnOverlayClicked?: boolean,
  onCloseClicked: () => void,
}

const FinsightModal = ({
  isFetching,
  modalTitle,
  children,
  successText,
  isSubmitSucceed,
  onCloseClicked,
  isVisible,
  isSuccessOverlay = true,
  ...otherProps
}: IFinsightModalProps) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      usePortal
      className={ styles.modalWrp }
      onCloseClicked={ onCloseClicked }
      isVisible={ isVisible }
      { ...otherProps }
    >
      <div className={ styles.modalLeftSide }>
        <div className={ styles.logo }>
          <i dangerouslySetInnerHTML={ { __html: finsightLogo } } />
        </div>
        <div className={ styles.centerContainer }>
          <span className={ styles.backgroundLogo }>
            <i dangerouslySetInnerHTML={ { __html: backgroundLogo } } />
          </span>
          <div className={ styles.centeredText }>
            { modalTitle }
          </div>
        </div>
        <div className={ styles.bottomText }>
          <p>Better Tools.</p>
          <p>Better Execution.</p>
        </div>
      </div>
      <div className={ styles.modalRightSide }>
        <Spinner
          isVisible={ isFetching }
          overlay
        />
        <div className={ styles.modalDescriptionContainer }>
          { children }
          {
            isSuccessOverlay && (
              <SuccessOverlay
                successText={ successText }
                isSubmitSucceed={ isSubmitSucceed }
                onCloseModalClick={ onCloseClicked }
              />
            )
          }
        </div>
      </div>
    </Modal>
  );
};

export default FinsightModal;
