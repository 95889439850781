import React from 'react';
import IconSearch from '@dealroadshow/uikit/core/components/Icon/IconSearch';
import styles from './searchInput.scss';

const SearchIcon = () => (
  <div className={ styles.searchIconContainer }>
    <IconSearch
      className={ styles.searchIcon }
    />
  </div>
);

export default SearchIcon;
