import React, { useRef, useEffect, useCallback } from 'react';
import cn from 'classnames';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import SearchResults from './SearchResults';
import headerStyles from '@/finsight/ui/common/components/header/Header/header.scss';
import styles from './searchResultsMenu.scss';
import { isEscapeKey } from '@/Framework/browser/checkPressedKey';
import { HEADER_SEARCH_INPUT_ID } from '../constants';
import { useHeaderMenuContext } from '@/finsight/ui/common/components/header/Header/HeaderMenuContext';
import { useDictionariesContext } from '@/finsight/application/Dictionaries/DictionariesContext';

interface IProps {
  isSearchFetching: boolean,
  isMissingSomethingModalVisible: boolean,
}

const SearchResultsMenu = ({
  isSearchFetching,
  isMissingSomethingModalVisible,
}: IProps) => {
  const searchResultsRef = useRef();
  const { isHeaderInitialized } = useDictionariesContext();
  const {
    isSearchResultsOpen,
    setIsSearchResultsOpen,
  } = useHeaderMenuContext();

  const searchResultHandlerEsc = useCallback((event) => {
    const isEscape = isEscapeKey(event);

    if (!isMissingSomethingModalVisible) {
      if (isEscape && isSearchResultsOpen) {
        // @ts-ignore
        setIsSearchResultsOpen(false);
      }
    }
  }, [isMissingSomethingModalVisible, isSearchResultsOpen]);

  const searchResultHandlerOutsideClick = useCallback((ev) => {
    // @ts-ignore
    if (!!ev.target && searchResultsRef.current && !searchResultsRef.current.contains(ev.target)) {
      if (
        isSearchResultsOpen
        && (ev.target.id !== HEADER_SEARCH_INPUT_ID)
        && (ev.target.offsetParent?.id !== HEADER_SEARCH_INPUT_ID)
      ) {
        // @ts-ignore
        setIsSearchResultsOpen(false);
      }
    }
  }, [searchResultsRef.current, isSearchResultsOpen]);

  useEffect(() => {
    if (isSearchResultsOpen && !isMissingSomethingModalVisible) {
      document.addEventListener('click', searchResultHandlerOutsideClick);
      document.addEventListener('keydown', searchResultHandlerEsc);
    }

    return () => {
      document.removeEventListener('click', searchResultHandlerOutsideClick);
      document.removeEventListener('keydown', searchResultHandlerEsc);
    };
  }, [
    isSearchResultsOpen,
    searchResultHandlerOutsideClick,
    searchResultHandlerEsc,
  ]);

  useEffect(() => {
    if (isSearchFetching) {
      // @ts-ignore
      searchResultsRef.current.scrollTop = 0;
    }
  }, [isSearchFetching]);

  return (
    <div
      ref={ searchResultsRef }
      className={
        cn(styles.container, {
          [styles.isSearchFetching]: isSearchFetching,
          [styles.isSearchResultsOpen]: isSearchResultsOpen,
        })
      }
    >
      <Spinner
        overlay
        className={ headerStyles.spinner }
        overlayClassName={ headerStyles.spinnerOverlay }
        isVisible={ !isHeaderInitialized || isSearchFetching }
      />
      { isHeaderInitialized && <SearchResults /> }
    </div>
  );
};

export default SearchResultsMenu;
