import useGetLoginUrl from '@/finsight/ui/common/components/header/Header/UserProfile/useGetLoginUrl';
import IconLock from '@dealroadshow/uikit/core/components/Icon/IconLock';
import styles from '@/finsight/ui/common/components/header/Header/UserProfile/userProfile.scss';

const UserProfileFallback = () => {
  const loginUrl = useGetLoginUrl();

  return (
    <a
      className={ styles.premiumizationLoginButton }
      data-test="loginLink"
      href={ loginUrl }
    >
      <div className={ styles.iconContainerPremiumization }>
        <IconLock />
        <span>Log In</span>
      </div>
    </a>
  );
};

export default UserProfileFallback;
