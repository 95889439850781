import React, { FC, useCallback } from 'react';
import cn from 'classnames';
import { Link as NavLink } from '@/Framework/Router/Next/Link';
import noop from 'lodash/noop';
import IconExternalLink from '@dealroadshow/uikit/core/components/Icon/IconExternalLink';
import styles from './headerLinkItem.scss';

interface ILinkTo {
  pathname: string,
  search: string,
}

interface IHeaderLinkItem {
  linkTo: any | ILinkTo,
  onClick?: () => void,
  children: React.ReactNode,
  className?: string,
  activeClassName?: string,
  toggleIsExpanded?: (x: boolean) => void,
  isActive?: boolean,
  isWithExpand?: boolean,
  isExpanded?: boolean,
  isExternal?: boolean,
  withIcon?: boolean,
}

const HeaderLinkItem: FC<IHeaderLinkItem> = ({
  onClick,
  children,
  linkTo,
  className = '',
  activeClassName = '',
  isActive = false,
  isWithExpand = false,
  isExpanded = false,
  toggleIsExpanded = noop,
  isExternal = false,
  withIcon = false,
}) => {
  const windowHeight = window.innerHeight;

  const refCallback = useCallback((el) => {
    if (isActive && el) {
      const { top, bottom } = el.getBoundingClientRect();
      if (top - 63 < 0 || bottom + 15 > windowHeight) {
        el.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  }, [windowHeight, isActive]);

  if (isExternal) {
    return (
      <a
        target="_blank"
        ref={ refCallback }
        href={ linkTo }
        className={ cn(className, styles.externalLink, {
          [activeClassName]: isActive,
        }) }
        onClick={ onClick }
      >
        { children }
        { withIcon && (
          <IconExternalLink
            className={ styles.externalIcon }
          />
        ) }
      </a>
    );
  }

  return (
    <div className={ styles.headerLinkItemContainer }>
      <NavLink
        ref={ refCallback }
        to={ linkTo }
        className={ cn(className, {
          [activeClassName]: isActive,
        }) }
        onClick={ onClick }
        activeClassName={ activeClassName }
      >
        { children }
      </NavLink>
      { isWithExpand && (
        <div
          className={ styles.toggleIconWrapper }
          onClick={ toggleIsExpanded }
        >
          <div className={ cn(styles.toggleIcon, { [styles.open]: isExpanded }) } />
        </div>
      ) }
    </div>
  );
};

export default HeaderLinkItem;
