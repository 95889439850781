import React from 'react';
import UserPlanTag from '@/Framework/UI/Atoms/UserPlanTag';
import { UserPlanType } from '@/users/domain/UserPlan';
import ToolService from '@/finsight/domain/Tool';
import HeaderLinkItem from '@/finsight/ui/common/components/header/Header/HeaderLinkItem';
import {
  useHeaderDataActiveMenuContext,
} from '@/finsight/ui/common/components/header/Header/HeaderMenuActiveDataContext';
import { ProductAbbreviations } from '@/finsight/domain/Product';

import styles from './tool.scss';
import headerStyles from '@/finsight/ui/common/components/header/Header/header.scss';
import IconExternalLink from '@dealroadshow/uikit/core/components/Icon/IconExternalLink';
import { IconComponentType } from '@dealroadshow/uikit/core/components/Icon';

interface IProps {
  onToolClick: Function,
  skipProductRegionAppend?: boolean,
  route?: string,
  id: string,
  name: string,
  additionalName?: string,
  new?: boolean,
  beta?: boolean,
  pro?: boolean,
  external: boolean,
  isActive?: boolean,
  outOfDomain: boolean,
  path: string,
}

const Tool = React.memo((props: IProps) => {
  const {
    activeProduct,
    activeRegion,
    activeMapping,
  } = useHeaderDataActiveMenuContext();
  const toolLink = !props.outOfDomain ? ToolService.getToolLink({
    isExternal: props.external,
    id: props.id,
    route: props.route,
    skipProductRegionAppend: props.skipProductRegionAppend,
    activeProductAbbreviation: activeProduct.abbreviation,
    activeRegionAbbreviation: activeRegion.abbreviation,
  }) : props.path;

  const onClick = () => props.onToolClick({ tool_name: props.name });
  const IconComponent = ToolService.getToolIconByID(props.id) as IconComponentType;
  return (
    <div className={ styles.toolWrp } data-test="toolWrapper">
      <HeaderLinkItem
        className={ styles.tool }
        activeClassName={ styles.activeLink }
        isActive={ props.isActive }
        linkTo={ toolLink }
        onClick={ onClick }
        isExternal={ props.external }
      >
        <IconComponent className={ styles.toolsIcon } />
        { ToolService.getToolName({
          id: props.id,
          isABSProduct: activeMapping.productAbbreviation === ProductAbbreviations.ABS,
          name: props.name,
          additionalName: props.additionalName,
        }) }
        { props.new && (<span className={ headerStyles.new }>(New)</span>) }
        { props.beta && (<span className={ headerStyles.beta }>(Beta)</span>) }
        { props.pro && (<UserPlanTag userPlan={ UserPlanType.PRO } className={ styles.userPlanTag } />) }
        { props.external && (
          <IconExternalLink className={ styles.toolsExternalIcon } />
        ) }
      </HeaderLinkItem>
    </div>
  );
});

export default Tool;
