import React from 'react';
import Input from '@dealroadshow/uikit/core/components/Input';
import IconEmail from '@dealroadshow/uikit/core/components/Icon/IconEmail';
import { IFinalFormFieldInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';

interface IProps extends IFinalFormFieldInputComponentProps {
  className?: string,
  placeholder?: string,
  dataTest: string,
}

const EmailInput = ({
  className,
  placeholder = 'Email',
  dataTest,
  ...props
}: IProps) => (
  <Input
    type="email"
    name="email"
    placeholder={ placeholder }
    icon={ IconEmail }
    className={ className }
    dataTest={ dataTest }
    { ...props }
  />
);

export default EmailInput;
