import React, { Component, createRef } from 'react';
import styles from './uploadFile.scss';
import { buttonStyles } from '@dealroadshow/uikit/core/components/Button';
import {
  FILE_EXTENSIONS,
  FILE_SIZE_LIMIT,
  ALLOWED_EXTENSIONS,
} from './constants';
import IconClose from '@dealroadshow/uikit/core/components/Icon/IconClose';

interface IProps {
  formName: string,
}

class UploadFile extends Component<IProps, any> {
  constructor(props) {
    super(props);

    // @ts-ignore
    this.inputFileRef = createRef();
    this.state = {
      uploadedFile: false,
      file: {},
      fileName: '',
      errorMessage: '',
      isFileInvalid: false,
    };
  }

  getFile = () => this.state.file;

  onAddFile = (e) => {
    let file = e.target.files[0];

    if (file) {
      let size = (file.size / (1000 * 1000)).toFixed(2);
      let { name } = file;
      if (+size > FILE_SIZE_LIMIT) {
        this.setState({
          isFileInvalid: true,
          errorMessage: `The maximum file size limit is ${ FILE_SIZE_LIMIT }MB.
        Please try re-uploading with smaller file size.`,
        });
      } else if (!FILE_EXTENSIONS.test(name)) {
        this.setState({
          isFileInvalid: true,
          errorMessage: `This file type is prohibited. Valid extensions are: ${
            ALLOWED_EXTENSIONS.map((extension) => extension.toUpperCase()).join(', ')
          }`,
        });
      } else {
        this.setState({
          isFileInvalid: false,
          uploadedFile: true,
          fileName: file.name,
          file,
        });
      }
    }
  };

  getContentComponent() {
    if (this.state.isFileInvalid) {
      return <span className={ styles.errorMessage }>{ this.state.errorMessage } </span>;
    }
    if (this.state.uploadedFile) {
      return (
        <div className={ styles.fileItem }>
          <label className={ styles.fileName }>{ this.state.fileName } </label>
          <div
            className={ styles.deleteIconWrapper }
            onClick={ this.deleteFile }
          >
            <IconClose
              className={ styles.deleteIcon }
            />
          </div>
        </div>
      );
    }

    return <label className={ styles.noFileLabel }>No file selected.</label>;
  }

  deleteFile = () => {
    // @ts-ignore
    this.inputFileRef.current.value = '';
    this.setState({
      isFileInvalid: false,
      uploadedFile: false,
      fileName: '',
      file: {},
    });
  };

  render() {
    return (
      <div className={ styles.uploadContainer }>
        <label className={ styles.uploadTitle }>supporting documentation</label>
        <div className={ styles.inputUploadContainer }>
          <label
            htmlFor={ `${ this.props.formName }DocumentationFile` }
            className={ buttonStyles.outline }
          >
            Browse
          </label>
          <input
            type="file"
            id={ `${ this.props.formName }DocumentationFile` }
            className={ styles.uploadInput }
            onChange={ this.onAddFile }
            // @ts-ignore
            ref={ this.inputFileRef }
          />
          { this.getContentComponent() }
        </div>
        <p>
          For faster turn around and verification purposes, please provide any supporting documents (e.g. Final OM,
          syndicate email, URL). Attach multiple files via Zip.
        </p>
      </div>
    );
  }
}

export default UploadFile;
