import React, { useState, useEffect, useRef } from 'react';
import noop from 'lodash/noop';
import cn from 'classnames';
import Input from '@dealroadshow/uikit/core/components/Input';
import SearchIcon from './SearchIcon';
import { useHeaderMenuContext } from '@/finsight/ui/common/components/header/Header/HeaderMenuContext';
import { HEADER_SEARCH_INPUT_ID } from '../../constants';
import { useDictionariesContext } from '@/finsight/application/Dictionaries/DictionariesContext';

import styles from './searchInput.scss';

interface IProps {
  value: string,
  pathname?: string,
  onChange?: (event: any, products: any, regions: any) => void,
  onFocus?: (event: any) => void,
  onClick?: (event: any) => void,
  onBlur?: (event: any) => void,
}

const SearchInput = ({
  pathname = '',
  value,
  onChange = noop,
  onFocus = noop,
  onClick = noop,
  onBlur = noop,
}: IProps) => {
  const {
    productsList,
    regionsList,
  } = useDictionariesContext();
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const {
    setIsSearchResultsOpen,
  } = useHeaderMenuContext();

  const inputRef = useRef();

  useEffect(() => {
    if (pathname === '/' && inputRef.current) {
      // @ts-ignore
      inputRef.current.focus();
    }
  }, [pathname]);

  const handlerOnClick = (ev) => {
    setIsSearchFocused(true);
    // @ts-ignore
    setIsSearchResultsOpen(!!ev?.target?.value);
    if (typeof onClick === 'function') {
      onClick(ev);
    }
  };

  const handlerOnFocus = (ev) => {
    setIsSearchFocused(true);
    // @ts-ignore
    setIsSearchResultsOpen(!!ev?.target?.value);
    if (typeof onFocus === 'function') {
      onFocus(ev);
    }
  };

  const handlerOnBlur = (ev) => {
    setIsSearchFocused(false);
    if (typeof onBlur === 'function') {
      onBlur(ev);
    }
  };

  const handlerOnChange = (ev) => {
    // @ts-ignore
    setIsSearchResultsOpen(!!ev.target.value);
    if (typeof onChange === 'function') {
      onChange(ev, productsList, regionsList);
    }
  };

  return (
    <div className={ styles.searchContainer }>
      <Input
        className={ cn(styles.searchInputWrapper,
          { [styles.searchIconIsActive]: isSearchFocused },
        ) }
        value={ value }
        placeholder="Search: Parents, Issuers or Deals"
        ref={ inputRef }
        name="headerSearchInput"
        dataTest="headerSearchInput"
        type="text"
        inputClassName={ styles.searchInput }
        prependComponent={ SearchIcon }
        onChange={ handlerOnChange }
        onFocus={ handlerOnFocus }
        onClick={ handlerOnClick }
        onBlur={ handlerOnBlur }
        // @ts-ignore
        input={ { id: HEADER_SEARCH_INPUT_ID } }
        isNarrow
        size="small"
      />
    </div>
  );
};

export default SearchInput;
