import React from 'react';
import cn from 'classnames';
import { Link } from '@/Framework/Router/Next/Link';
import URL from '@/finsight/Router/urlConstants';
import { useBreadcrumbsContext } from '@/finsight/ui/common/components/header/Breadcrumbs';

import styles from './breadcrumbs.scss';
import { IconComponentType } from '@dealroadshow/uikit/core/components/Icon';
import IconArrowRightBold from '@dealroadshow/uikit/core/components/Icon/IconArrowRightBold';

interface IProps {
  breadcrumbs?: [],
  wrapperClassName?: string,
  icon?: IconComponentType,
}

const Breadcrumbs = ({
  breadcrumbs,
  wrapperClassName,
  icon: Icon = IconArrowRightBold,
}: IProps) => {
  const { breadcrumbs: breadcrumbsVal } = useBreadcrumbsContext();

  if (!(breadcrumbs || breadcrumbsVal || []).length) {
    return null;
  }

  return (
    <div className={ cn(styles.breadcrumbsWrapper, wrapperClassName) }>
      <div className={ styles.breadcrumbsContentWrapper }>
        { (breadcrumbs || breadcrumbsVal || []).map((breadcrumb: any) => (
          <div key={ breadcrumb.title }>
            { !breadcrumb.isActive && (
              <div className={ styles.linkWrapper }>
                <Link
                  className={ styles.link }
                  to={ breadcrumb.url }
                >
                  { breadcrumb.title }
                </Link>
                { breadcrumb.withParentLink && (
                  <>
                    <div className={ styles.parentLinkDiv } />
                    <Link
                      className={ styles.link }
                      to={ URL.SPONSORS_LIST }
                    >
                      Parent Companies
                    </Link>
                  </>
                ) }
                <span>
                  <Icon className={ styles.breadcrumbsIcon } />
                </span>
              </div>
            ) }
            { breadcrumb.isActive && (
              <p className={ styles.inactiveLink }>{ breadcrumb.title }</p>
            ) }
          </div>
        )) }
      </div>
    </div>
  );
};

export default Breadcrumbs;
